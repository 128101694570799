// libs
import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// components 
import Dashboard from './pages/dashboard';
import ForgotPassword from './pages/credentials/forgot-password.js'; 
import Login from './pages/credentials/login.js';
import WinsightList from "./pages/winsight/list";
import PublicUrlList from "./pages/public-url-product/list"
import ProductList from "./pages/products/product/list";
import InventoryList from "./pages/inventory/list";
import SalesList from "./pages/sales/list";
import SalesAnalyticsList from "./pages/sales-analytics/index.js";
import SalesViaSKU from "./pages/sales-via-sku/list.js";
import Upload from "./pages/uploads/image";
import PackageInfo from "./pages/package-dimensions/list";
// config & functions 
import userToken from "./utils/user-token";
import utils from './utils';

const App = () => {
	const { token, setToken } = userToken();
	const [OrgInfo, setOrgInfo] = useState({user: '', domain: '', roleId: ''});

	const validateToken = async () => {
		// decode jwt token and get username
		if(token) {
			const tokenVal = await utils.decodeJWT(token);
			if(tokenVal && tokenVal.hasOwnProperty('data')) {
				const data = tokenVal.data;
				// let id = (data.id) ? data.id : null;
				let email = (data.email) ? data.email : null;
				let domain = (data.orgDomain) ? data.orgDomain : null;
				let roleId = (data.roleId) ? data.roleId : null;
				setOrgInfo({user: email, domain, roleId})
			}
		}
	}

	useEffect(() => {
		validateToken();
	}, [])

	if(!token) {
		return <Login setToken={setToken} orgInfo={OrgInfo} />
	} else {
		return (
			<Router>
				<Routes>
					<Route exact path="/" element={<Dashboard token={token} orgInfo={OrgInfo} />} />
					<Route path="/forgot-password" element={<ForgotPassword token={token} orgInfo={OrgInfo} />} />
					<Route path="/dashboard" element={<Dashboard token={token} orgInfo={OrgInfo} />} />
					{/* winsight */}
					<Route path="/winsight" element={<WinsightList token={token} orgInfo={OrgInfo} />} />
					{/* public url */}
					<Route path="/public-url-products" element={<PublicUrlList token={token} orgInfo={OrgInfo} />} />
					{/* product */}
					<Route path="/products" element={<ProductList token={token} orgInfo={OrgInfo} />} />
					<Route path="/uploads" element={<Upload token={token} orgInfo={OrgInfo} />} />
					<Route path="/inventory" element={<InventoryList token={token} orgInfo={OrgInfo} />} />
					<Route path="/sales" element={<SalesList token={token} orgInfo={OrgInfo} />} />
					<Route path="/sales-analytics" element={<SalesAnalyticsList token={token} orgInfo={OrgInfo} />} />
					<Route path="/sales-via-sku" element={<SalesViaSKU token={token} orgInfo={OrgInfo} />} />
					<Route path="/package-dimensions" element={<PackageInfo token={token} orgInfo={OrgInfo} />} />
				</Routes>
			</Router>
		)
	}
}

export default App;
