// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/css/form.css";
// components 
import HistoryEditModal from './history-edit-modal';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";
// ...

function HistoryListModal({setshowhistorymodalbox, rowid, token, orginfo}) {

    const [InputFields, setInputFields] = useState({
        historyInfo: ""
    });
    const [ShowFormModalBox, setShowFormModalBox] = useState(false);
    const [HistoryRowId, setHistoryRowId] = useState(0);
    const wrapperRef = useRef(null);
    const [Notification, setNotification] = useState({ status: (rowid?.ppid) ? true : false, message: 'Please wait while we fetch your record' })

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowhistorymodalbox(false)
	}

    const handEditModal = (hId) => {
        setHistoryRowId(hId);
        setShowFormModalBox(true);
    }

    const fetchData = async () => {
        if (rowid?.ppid && rowid?.pvid) {
            let apiCall = await ajaxCall.getCall(`${config.api.generic}/inventory/stock-history`, {
                inventoryId: (rowid?.ppid) ? rowid?.ppid : 0,
                variantId: (rowid?.pvid) ? rowid?.pvid : 0,
            }, token);
            if(apiCall && apiCall.status) {
                let onLoadData = {
                    historyInfo: apiCall.context.message.data
                }
                setInputFields(onLoadData)
                setNotification({ status: false })
            } else {
                setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
            }
        }
    }

    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }
        fetchData();
        loadComponent();
    }, []);

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Stock History</h5>
                            <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <form action="" method="" className="p-sm-1">
                                        <div className="row">
                                            {(Notification.status) ?
                                                <div className="col-12 text-center">{Notification.message}</div> : <>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Stock History</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="fs-14rem fw-bold">Account: {InputFields.historyInfo.inventory[0].org_name}</div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="fs-14rem fw-bold">Product: {InputFields.historyInfo.inventory[0].product_name}</div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="fs-14rem fw-bold">Color: {InputFields.historyInfo.inventory[0].color_label}</div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="fs-14rem fw-bold">Size: {InputFields.historyInfo.inventory[0].size_label}</div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="fs-14rem fw-bold">SKU: {InputFields.historyInfo.inventory[0].skuid}</div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="table-responsive">
                                                            {InputFields?.historyInfo?.inwardHistory && <table className="table fs-14rem">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{minWidth: '70px'}}>Sl No</th>
                                                                        <th style={{minWidth: '140px'}}>Seller <span className="fs-11rem">(Supplier)</span></th>
                                                                        <th style={{minWidth: '100px'}}>Operation</th>
                                                                        <th style={{minWidth: '85px'}}>Quantity</th>
                                                                        <th style={{minWidth: '145px'}}>Amount (Per Pcs)</th>
                                                                        <th style={{minWidth: '70px'}}>Tax</th>
                                                                        <th style={{minWidth: '120px'}}>Delivery Date</th>
                                                                        <th style={{minWidth: '85px'}}>Invoice</th>
                                                                        <th style={{minWidth: '140px'}}>Comments</th>
                                                                        <th style={{minWidth: '80px'}}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {InputFields.historyInfo.inwardHistory.map((ele, index) => {
                                                                        return <tr key={Math.random()}>
                                                                            <td>{index+1}</td>
                                                                            <td>{ele.seller_org_account_name}</td>
                                                                            <td>{ele.operation}</td>
                                                                            <td>{ele.inward_quantity} {ele.inward_suffix}</td>
                                                                            <td>Rs {ele.amount}/-</td>
                                                                            <td>{ele.tax}%</td>
                                                                            <td>{ele.delivery_date}</td>
                                                                            <td>{ele.invoice_no}</td>
                                                                            <td>{ele.comments}</td>
                                                                            <td>
                                                                                <button onClick={() => handEditModal(ele.pk_id)} className="border-0 bg-transparent text-primary">Edit</button>
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                                    <tr>
                                                                        <td></td>
                                                                        <td className="fw-bold">Total Inward</td>
                                                                        <td className="fw-bold">{InputFields.historyInfo.stockSummary?.totalInward?.stock} {InputFields.historyInfo.inwardHistory[0]?.inward_suffix}</td>
                                                                        <td className="fw-bold" colSpan={2}>Rs {InputFields.historyInfo.stockSummary?.totalInward?.amount}/-</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td className="fw-bold">Total CuttOff</td>
                                                                        <td className="fw-bold">{InputFields.historyInfo.stockSummary?.totalCutOff?.stock || 0} {InputFields.historyInfo.inwardHistory[0]?.inward_suffix}</td>
                                                                        <td className="fw-bold" colSpan={2}>Rs {InputFields.historyInfo.stockSummary?.totalCutOff?.amount || 0}/-</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>}
                                                        </div>
                                                    </div>
                                                </>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {ShowFormModalBox? <HistoryEditModal loadapidata={fetchData} setshowformmodalbox={setShowFormModalBox} historyrowid={HistoryRowId} variantid={(rowid?.pvid)?rowid?.pvid : 0} token={token} orginfo={orginfo} /> : null}
        </>
    )
}

export default HistoryListModal;