// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/css/form.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components 
import Loading from '../../components/loading';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";
import utils from '../../utils';

function FormModal({loadapidata, apidataparams, setshowformmodalbox, rowid, token, orginfo}) {

    const [InputFields, setInputFields] = useState({
        rowId: "",
        accountId: "",
        warehouseId: "",
        sellerAccountId: "",
        customerName: "",
        customerPhNo: "",
        customerCity: "",
        customerState: "",
        customerPincode: "",
        customerAddress: "",
        orderDate: "",
        dispatchDate: "",
        brand: "",
        platformId: "",
        orderType: "",
        orderId: "",
        itemId: "",
        skuID: "",
        productName: "",
        sizeId: "",
        sizeLabel: "",
        colorId: "",
        colorLabel: "",
        quantity: "",
        sellingPrice: "",
        isReturned: "",
        returnArrivalDate: "",
        returnDeliveredStatusId: "",
        returnTypeId: "",
        returnFees: "",
        returnReason: "",
        claimForRefund: "",
        claimStatus: "",
        claimAmt: 0,
        settlementAmt: 0,
        penaltyAmt: "",
        claimRejectionReason: "",
        avialableToResell: "",
        reasonNotToResell: "",
        comments: ""
    });
    const disabledField = (orginfo.roleId === 1 || orginfo.roleId === 2) ? false:true;
    const wrapperRef = useRef(null);
    const [ShowAllFields, setShowAllFields] = useState((rowid) ? true : false);
    const [SubmitButton, setSubmitButton] = useState((rowid) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (rowid) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(true);
    const [DisableSkuInfoButton, setDisableSkuInfoButton] = useState(false);
    const [PlatformList, setPlatformList] = useState(null);
    const [OrderType, setOrderType] = useState(null);
    const [AccountNames, setAccountNames] = useState(null);
    const [WarehouseNames, setWarehouseNames] = useState(null);
    const [ClaimStatus, setClaimStatus] = useState(null);
    const [SkuIdInputVal, setSkuIdInputVal] = useState('');
    const [AccountSelectionVal, setAccountSelectionVal] = useState('');
    const [SellerSelectionVal, setSellerSelectionVal] = useState('');
    const [WarehouseSelectionVal, setWarehouseSelectionVal] = useState('');
    const [ReturnDeliveryStatus, setReturnDeliveryStatus] = useState('');
    const [ReturnType, setReturnType] = useState('');

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowformmodalbox(false)
	}

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const updateAccountChangeEvent = (value, field) => {
		field.onChange(value);
        setAccountSelectionVal(value.target.value);
	}
    
    const updateSellerChangeEvent = (value, field) => {
		field.onChange(value);
        setSellerSelectionVal(value.target.value);
	}
    
    const updateWarehouseChangeEvent = (value, field) => {
		field.onChange(value);
        setWarehouseSelectionVal(value.target.value);
	}

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        let nOrderDate = utils.getDateFromTimestamp(data.orderDate);
        let nDispatchDate = utils.getDateFromTimestamp(data.dispatchDate);
        let nreturnDate = '0000-00-00';
        if(rowid) {
            nreturnDate = utils.getDateFromTimestamp(data.returnArrivalDate);
        }
        
        let postData = {
            rowId: rowid,
            accountId: (rowid) ? InputFields.accountId : data.accountId,
            warehouseId: data.warehouseId,
            sellerAccountId: data.sellerAccountId,
            customerName: data.customerName,
            customerPhNo: data.customerPhNo,
            customerCity: data.customerCity,
            customerState: data.customerState,
            customerPincode: data.customerPincode,
            customerAddress: data.customerAddress,
            orderDate: nOrderDate,
            dispatchDate: nDispatchDate,
            brand: data.brand,
            platformId: data.platformId,
            orderType: data.orderType,
            orderId: data.orderId,
            itemId: data.itemId,
            skuID: data.skuID,
            productName: InputFields.productName,
            sizeId: InputFields.sizeId,
            colorId: InputFields.colorId,
            quantity: data.quantity,
            sellingPrice: data.sellingPrice,
            isReturned: (ShowAllFields) ? data.isReturned || 'No' : 'No',
            returnArrivalDate: nreturnDate,
            returnDeliveredStatusId: (ShowAllFields) ? data.returnDeliveredStatusId : 4,
            returnTypeId: (ShowAllFields) ? data.returnTypeId : 3,
            returnFees: (ShowAllFields) ? data.returnFees : 0,
            returnReason: (ShowAllFields) ? data.returnReason : '',
            claimForRefund: (ShowAllFields) ? data.claimForRefund || 'No' : 'No',
            claimStatus: (ShowAllFields) ? data.claimStatus || 1 : 1,
            claimAmt: (ShowAllFields) ? data.claimAmt || 0 : 0,
            settlementAmt: data.settlementAmt,
            penaltyAmt: (ShowAllFields) ? data.penaltyAmt : 0,
            claimRejectionReason: (ShowAllFields) ? data.claimRejectionReason : '',
            avialableToResell: (ShowAllFields) ? data.avialableToResell || 'No' : 'No',
            reasonNotToResell: (ShowAllFields) ? data.reasonNotToResell : '',
            comments: data.comments
        }
        let apiCall = "";
        if(InputFields.rowId) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/sales`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/sales`, postData, token);
        }
        
        if(apiCall && apiCall.status) {
            setSubmitButton((InputFields.rowId)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
            loadapidata(apidataparams); // refresh list page
        } else {
            setSubmitButton((InputFields.rowId)?'UPDATE RECORD':'SAVE RECORD');
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }
    
    const fetchAccountNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/get-names`, {}, token);
        if(apiCall && apiCall.status) {
            setAccountNames(apiCall.context.message.data);
        } else {
            setAccountNames("Something went wrong. Please try agian")
        }
    }

    const fetchWarehouseNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/warehouse`, {}, token);
        if(apiCall && apiCall.status) {
            setWarehouseNames(apiCall.context.message.data);
        } else {
            setWarehouseNames("Something went wrong. Please try agian")
        }
    }

    const fetchPlatformList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/platform`, {}, token);
        if(apiCall && apiCall.status) {
            setPlatformList(apiCall.context.message.data);
        } else {
            setPlatformList("Something went wrong. Please try agian")
        }
    }
   
    const fetchOrderType = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/order-type`, {}, token);
        if(apiCall && apiCall.status) {
            setOrderType(apiCall.context.message.data);
        } else {
            setOrderType("Something went wrong. Please try agian")
        }
    }

    const fetchReturnType = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/order/return-type`, {}, token);
        if(apiCall && apiCall.status) {
            setReturnType(apiCall.context.message.data);
        } else {
            setReturnType("Something went wrong. Please try agian")
        }
    }

    const fetchReturnDeliveryStatus = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/order/return-delivery-status`, {}, token);
        if(apiCall && apiCall.status) {
            setReturnDeliveryStatus(apiCall.context.message.data);
        } else {
            setReturnDeliveryStatus("Something went wrong. Please try agian")
        }
    }

    const handleChangeSkuName = (event) => {
        setSkuIdInputVal(event.target.value);
    }

    const getSKUInfo = async () => {
        setDisableSkuInfoButton(true);
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/sales/sku-info`, {skuid: SkuIdInputVal || InputFields.skuID, accountId: AccountSelectionVal || InputFields.accountId, sellerAccountId: SellerSelectionVal || InputFields.sellerAccountId, warehouseId: WarehouseSelectionVal || InputFields.warehouseId}, token);
        if(apiCall && apiCall.status) {
            let val = apiCall.context.message.data[0];
            let obj = InputFields;
            const newObj = {...obj, productName: val.product_name, sizeId: val.fk_size_id, sizeLabel: val.size_label, colorId: val.fk_color_id, colorLabel: val.color_label};
            setInputFields(newObj);
            setDisableSkuInfoButton(false);
            setDisableSubmitButton(false);
        } else {
            let obj = InputFields;
            const newObj = {...obj, productName: '', sizeId: '', sizeLabel: '', colorId: '', colorLabel: ''};
            setInputFields(newObj);
            setDisableSkuInfoButton(false);
            setDisableSubmitButton(true);
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
        }
    }
    
    const fetchClaimStatus = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/claim-status`, {}, token);
        if(apiCall && apiCall.status) {
            setClaimStatus(apiCall.context.message.data);
        } else {
            setClaimStatus("Something went wrong. Please try agian")
        }
    }
    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (rowid) {
                setShowAllFields(true);
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/sales/row`, {
                    rowId: (rowid) ? rowid : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let od = new Date();
                    if(apiCall.context.message.data[0].order_date && apiCall.context.message.data[0].order_date!=='0000-00-00') {
                        od = new Date(apiCall.context.message.data[0].order_date);
                    }

                    let dd = new Date();
                    if(apiCall.context.message.data[0].dispatch_date && apiCall.context.message.data[0].dispatch_date!=='0000-00-00') {
                        dd = new Date(apiCall.context.message.data[0].dispatch_date);
                    }

                    let rad = new Date();
                    if(apiCall.context.message.data[0].return_arrival_date && apiCall.context.message.data[0].return_arrival_date!=='0000-00-00') {
                        rad = new Date(apiCall.context.message.data[0].return_arrival_date);
                    }
                    let onLoadData = {
                        rowId: rowid,
                        accountId: apiCall.context.message.data[0].fk_org_account_id,
                        warehouseId: apiCall.context.message.data[0].fk_warehouse_id,
                        sellerAccountId: apiCall.context.message.data[0].fk_seller_org_account_id,
                        customerName: apiCall.context.message.data[0].customer_name,
                        customerPhNo: apiCall.context.message.data[0].customer_phone,
                        customerCity: apiCall.context.message.data[0].customer_city,
                        customerState: apiCall.context.message.data[0].customer_state,
                        customerPincode: apiCall.context.message.data[0].customer_pincode,
                        customerAddress: apiCall.context.message.data[0].customer_address,
                        orderDate: new Date(od),
                        dispatchDate: new Date(dd),
                        brand: apiCall.context.message.data[0].brand,
                        platformId: apiCall.context.message.data[0].fk_platform_type_id,
                        orderType: apiCall.context.message.data[0].fk_order_type_id,
                        orderId: apiCall.context.message.data[0].orderid,
                        itemId: apiCall.context.message.data[0].itemid,
                        skuID: apiCall.context.message.data[0].skuid,
                        productName: apiCall.context.message.data[0].product_name,
                        sizeId: apiCall.context.message.data[0].fk_size_id,
                        sizeLabel: apiCall.context.message.data[0].size_label,
                        colorId: apiCall.context.message.data[0].fk_color_id,
                        colorLabel: apiCall.context.message.data[0].color_label,
                        quantity: apiCall.context.message.data[0].quantity,
                        sellingPrice: apiCall.context.message.data[0].selling_price,
                        isReturned: apiCall.context.message.data[0].is_returned,
                        returnArrivalDate: new Date(rad),
                        returnDeliveredStatusId: apiCall.context.message.data[0].fk_return_delivery_status_id,
                        returnTypeId: apiCall.context.message.data[0].fk_return_type_id,
                        returnFees: apiCall.context.message.data[0].return_fees,
                        returnReason: apiCall.context.message.data[0].return_reason,
                        claimForRefund: apiCall.context.message.data[0].claim_for_refund,
                        claimStatus: apiCall.context.message.data[0].fk_claim_status_id,
                        claimAmt: apiCall.context.message.data[0].claim_amount,
                        settlementAmt: apiCall.context.message.data[0].settlement_amount,
                        penaltyAmt: apiCall.context.message.data[0].penalty_amount,
                        claimRejectionReason: apiCall.context.message.data[0].reason_for_claim_rejection,
                        avialableToResell: apiCall.context.message.data[0].available_to_sell,
                        reasonNotToResell: apiCall.context.message.data[0].reason_for_not_to_sell,
                        comments: apiCall.context.message.data[0].comments,
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            } else {
                setShowAllFields(false);
            }
        }
        fetchAccountNames();
        fetchWarehouseNames();
        fetchData();
        fetchPlatformList();
        fetchOrderType();
        fetchClaimStatus();
        fetchReturnType();
        fetchReturnDeliveryStatus();
        loadComponent();
    }, []);

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{rowid?'Update Sales Record':'Add Sales Record'}</h5>
                            <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            {(Notification.status) ?
                                                <div className="col-12 text-center">{Notification.message}</div> : <>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Order Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Choose Account <span className="fs-11rem">(Seller Account)</span></label>
                                                        {(AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0) ? <><Controller
                                                            name='accountId' 
                                                            defaultValue={InputFields.accountId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="accountId" onChange={(e) => {updateAccountChangeEvent(e, field)}} disabled={rowid?true:false}>
                                                                    <option value=""> Choose...</option>
                                                                    {AccountNames.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.accountId?.type === "required" && "Please choose Account"}
                                                        </small></> : (AccountNames === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{AccountNames}</div> }
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Choose Warehouse</label>
                                                        {(WarehouseNames && Array.isArray(WarehouseNames) && WarehouseNames.length > 0) ? <><Controller
                                                            name='warehouseId' 
                                                            defaultValue={InputFields.warehouseId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="warehouseId" onChange={(e) => {updateWarehouseChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {WarehouseNames.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.warehouseId?.type === "required" && "Please choose Warehouse"}
                                                        </small></> : (WarehouseNames === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{WarehouseNames}</div> }
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Choose Seller <span className="fs-11rem">(Supplier)</span></label>
                                                        {(AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0) ? <><Controller
                                                            name='sellerAccountId' 
                                                            defaultValue={InputFields.sellerAccountId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="sellerAccountId" onChange={(e) => {updateSellerChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {AccountNames.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.sellerAccountId?.type === "required" && "Please choose Seller Account"}
                                                        </small></> : (AccountNames === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{AccountNames}</div> }
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <div><label htmlFor="orderDate" className="form-label fs-14rem fw-bold">Order Date</label></div>
                                                        <div className="react-datepicker-wrapper-fullwidth">
                                                            <Controller
                                                                control={control}
                                                                name="orderDate"
                                                                defaultValue={InputFields.orderDate} 
                                                                rules={{ required: true }}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            className="form-control w-100"
                                                                            selected={field.value}
                                                                            format="DD" 
                                                                            onChange={(date) => {
                                                                                field.onChange(date);
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <small className="form-text text-danger">
                                                            {errors.orderDate?.type === "required" && "Order date is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <div><label htmlFor="dispatchDate" className="form-label fs-14rem fw-bold">Dispatch Date</label></div>
                                                        <div className="react-datepicker-wrapper-fullwidth">
                                                            <Controller
                                                                control={control}
                                                                name="dispatchDate"
                                                                defaultValue={InputFields.dispatchDate} 
                                                                rules={{ required: true }}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            className="form-control w-100"
                                                                            selected={field.value}
                                                                            format="DD" 
                                                                            onChange={(date) => {
                                                                                field.onChange(date);
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <small className="form-text text-danger">
                                                            {errors.dispatchDate?.type === "required" && "Dispatch date on is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Brand</label>
                                                        <input type="text" defaultValue={InputFields.brand} className="form-control" name="brand" {...register("brand", { required: false, minLength: 1, maxLength: 62 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.brand?.type === "required" && "Brand is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.brand?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.brand?.type === "maxLength" && "Only 62 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Platform</label>
                                                        {(PlatformList && Array.isArray(PlatformList) && PlatformList.length > 0) ? <><Controller
                                                            name='platformId' 
                                                            defaultValue={InputFields.platformId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="platformId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {PlatformList.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.platformId?.type === "required" && "Please update Platform"}
                                                        </small></> : (PlatformList === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{PlatformList}</div> }
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Order Type</label>
                                                        {(OrderType && Array.isArray(OrderType) && OrderType.length > 0) ? <><Controller
                                                            name='orderType' 
                                                            defaultValue={InputFields.orderType}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="orderType" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {OrderType.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.orderType?.type === "required" && "Please update Order Type"}
                                                        </small></> : (OrderType === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{OrderType}</div> }
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Order ID</label>
                                                        <input type="text" defaultValue={InputFields.orderId} className="form-control" name="orderId" {...register("orderId", { required: true, minLength: 4 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.orderId?.type === "required" && "Order ID is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.orderId?.type === "minLength" && "Enter atleast 4 characters"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Item ID</label>
                                                        <input type="text" defaultValue={InputFields.itemId} className="form-control" name="itemId" {...register("itemId", { required: true, minLength: 4 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.itemId?.type === "required" && "Item ID is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.itemId?.type === "minLength" && "Enter atleast 4 characters"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Quantity</label>
                                                        <input type="text" defaultValue={InputFields.quantity} className="form-control" name="quantity" {...register("quantity", { required: true })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.quantity?.type === "required" && "Quantity is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-8 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">SKU Id</label>
                                                        <div className="input-group">
                                                            <input type="text" defaultValue={InputFields.skuID} className="form-control" onKeyUp={handleChangeSkuName} name="skuID" {...register("skuID", { required: true, minLength: 4 })} disabled={disabledField} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-outline-primary" onClick={getSKUInfo} type="button" disabled={DisableSkuInfoButton?true:false}>{DisableSkuInfoButton?`Loading...`:`Get Details`}</button>
                                                            </div>
                                                        </div>
                                                        <small className="form-text text-danger">
                                                            {errors.skuID?.type === "required" && "SKU ID is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.skuID?.type === "minLength" && "Enter atleast 4 characters"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-8 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Product Name</label>
                                                        <div className="fs-14rem">{InputFields.productName || `-`}</div>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Size / Color</label>
                                                        <div className="fs-14rem">{InputFields.sizeLabel || `-`} / {InputFields.colorLabel || `-`}</div>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Selling Price</label>
                                                        <input type="text" defaultValue={InputFields.sellingPrice} className="form-control" name="sellingPrice" {...register("sellingPrice", { required: true })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.sellingPrice?.type === "required" && "Product selling price is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Settlement Amount</label>
                                                        <input type="text" defaultValue={InputFields.settlementAmt} className="form-control" name="settlementAmt" {...register("settlementAmt", { required: true })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.settlementAmt?.type === "required" && "Settlement Amount is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-4 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Panelty Amount</label>
                                                        <input type="text" defaultValue={InputFields.penaltyAmt} className="form-control" name="penaltyAmt" {...register("penaltyAmt", { required: ShowAllFields?true:false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.penaltyAmt?.type === "required" && "Panelty Amount is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-12 ${ShowAllFields?``:`d-none`}`}>
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Return Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className={`col-md-6 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Is Returned?</label>
                                                        <Controller
                                                            name='isReturned' 
                                                            defaultValue={InputFields.isReturned}
                                                            rules={{required: ShowAllFields?true:false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="isReturned" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    <option value="Yes"> Yes</option>
                                                                    <option value="No"> No</option>
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.isReturned?.type === "required" && "Please update Is Returned"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-6 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Return Type</label>
                                                        {(ReturnType && Array.isArray(ReturnType) && ReturnType.length > 0) ? <><Controller
                                                            name='returnTypeId' 
                                                            defaultValue={InputFields.returnTypeId}
                                                            rules={{required: ShowAllFields?true:false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="returnTypeId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {ReturnType.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.returnTypeId?.type === "required" && "Please update Return Type"}
                                                        </small></> : (ReturnType === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{ReturnType}</div> }
                                                    </div>
                                                    <div className={`col-md-6 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <div><label htmlFor="returnArrivalDate" className="form-label fs-14rem fw-bold">Return Arrival Date</label></div>
                                                        <div className="react-datepicker-wrapper-fullwidth">
                                                            <Controller
                                                                control={control}
                                                                name="returnArrivalDate"
                                                                defaultValue={InputFields.returnArrivalDate} 
                                                                rules={{ required: false }}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            className="form-control w-100"
                                                                            selected={field.value}
                                                                            format="DD" 
                                                                            onChange={(date) => {
                                                                                field.onChange(date);
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <small className="form-text text-danger">
                                                            {errors.returnArrivalDate?.type === "required" && "Return Arrival Date is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-6 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Return Delivered Status</label>
                                                        {(ReturnDeliveryStatus && Array.isArray(ReturnDeliveryStatus) && ReturnDeliveryStatus.length > 0) ? <><Controller
                                                            name='returnDeliveredStatusId' 
                                                            defaultValue={InputFields.returnDeliveredStatusId}
                                                            rules={{required: ShowAllFields?true:false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="returnDeliveredStatusId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {ReturnDeliveryStatus.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.returnDeliveredStatusId?.type === "required" && "Please update Delivery Status"}
                                                        </small></> : (ReturnDeliveryStatus === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{ReturnDeliveryStatus}</div> }
                                                    </div>
                                                    <div className={`col-md-4 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Return Fees</label>
                                                        <input type="text" defaultValue={InputFields.returnFees} className="form-control" name="returnFees" {...register("returnFees", { required: ShowAllFields?true:false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.returnFees?.type === "required" && "Return Fees is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-8 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Return Reason</label>
                                                        <input type="text" defaultValue={InputFields.returnReason} className="form-control" name="returnReason" {...register("returnReason", { required: false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.returnReason?.type === "required" && "Return Reason is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-12 ${ShowAllFields?``:`d-none`}`}>
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Resell Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className={`col-md-4 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Available to Resell?</label>
                                                        <Controller
                                                            name='avialableToResell' 
                                                            defaultValue={InputFields.avialableToResell}
                                                            rules={{required: ShowAllFields?true:false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="avialableToResell" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    <option value="Yes"> Yes</option>
                                                                    <option value="No"> No</option>
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.avialableToResell?.type === "required" && "Please update Is Returned"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-8 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Reason for Not to Resell</label>
                                                        <input type="text" defaultValue={InputFields.reasonNotToResell} className="form-control" name="reasonNotToResell" {...register("reasonNotToResell", { required: false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.reasonNotToResell?.type === "required" && "Reason for Claim Rejection is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-12 ${ShowAllFields?``:`d-none`}`}>
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Claim Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className={`col-md-4 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Claim for Refund</label>
                                                        <Controller
                                                            name='claimForRefund' 
                                                            defaultValue={InputFields.claimForRefund}
                                                            rules={{required: ShowAllFields?true:false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="claimForRefund" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    <option value="Yes"> Yes</option>
                                                                    <option value="No"> No</option>
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.claimForRefund?.type === "required" && "Please update Is Returned"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-4 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Claim Status</label>
                                                        {(ClaimStatus && Array.isArray(ClaimStatus) && ClaimStatus.length > 0) ? <><Controller
                                                            name='claimStatus' 
                                                            defaultValue={InputFields.claimStatus}
                                                            rules={{required: ShowAllFields?true:false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="claimStatus" onChange={(e) => {updateChangeEvent(e, field)}} disabled={disabledField}>
                                                                    <option value=""> Choose...</option>
                                                                    {ClaimStatus.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.claimStatus?.type === "required" && "Please choose Client"}
                                                        </small></> : (ClaimStatus === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{ClaimStatus}</div> }
                                                    </div>
                                                    <div className={`col-md-4 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Claim Amount</label>
                                                        <input type="text" defaultValue={InputFields.claimAmt} className="form-control" name="claimAmt" {...register("claimAmt", { required: ShowAllFields?true:false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.claimAmt?.type === "required" && "Claim Amount is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className={`col-md-12 mt-3 ${ShowAllFields?``:`d-none`}`}>
                                                        <label className="form-label fs-14rem fw-bold">Reason for Claim Rejection</label>
                                                        <input type="text" defaultValue={InputFields.claimRejectionReason} className="form-control" name="claimRejectionReason" {...register("claimRejectionReason", { required: false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.claimRejectionReason?.type === "required" && "Reason for Claim Rejection is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Shipping Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Customer Name</label>
                                                        <input type="text" defaultValue={InputFields.customerName} className="form-control" name="customerName" {...register("customerName", { required: true, maxLength: 122 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.customerName?.type === "required" && "Customer Name is mandatory"}
                                                            {errors.customerName?.type === "maxLength" && "Max length allowed 122"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Customer Phone No</label>
                                                        <input type="text" defaultValue={InputFields.customerPhNo} className="form-control" name="customerPhNo" {...register("customerPhNo", { required: false })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.customerPhNo?.type === "required" && "Customer Phone no is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">City</label>
                                                        <input type="text" defaultValue={InputFields.customerCity} className="form-control" name="customerCity" {...register("customerCity", { required: false, maxLength: 62 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.customerCity?.type === "required" && "City is mandatory"}
                                                            {errors.customerCity?.type === "maxLength" && "Max length allowed 62"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">State</label>
                                                        <input type="text" defaultValue={InputFields.customerState} className="form-control" name="customerState" {...register("customerState", { required: false, maxLength: 62 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.customerState?.type === "required" && "State is mandatory"}
                                                            {errors.customerState?.type === "maxLength" && "Max length allowed 62"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Pincode</label>
                                                        <input type="text" defaultValue={InputFields.customerPincode} className="form-control" name="customerPincode" {...register("customerPincode", { required: false, minLength: 6, maxLength: 6 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.customerPincode?.type === "required" && "Pincode is mandatory"}
                                                            {errors.customerPincode?.type === "minLength" && "Min length required 6"}
                                                            {errors.customerPincode?.type === "maxLength" && "Max length allowed 6"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Address</label>
                                                        <textarea defaultValue={InputFields.customerAddress} className="form-control" name="customerAddress" {...register("customerAddress", { required: true, minLength: 5, maxLength: 500 })} disabled={disabledField}></textarea>
                                                        <small className="form-text text-danger">
                                                            {errors.customerAddress?.type === "required" && "Address is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.customerAddress?.type === "minLength" && "Enter atleast 5 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.customerAddress?.type === "maxLength" && "Only 500 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Others</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Comments</label>
                                                        <textarea defaultValue={InputFields.comments} className="form-control" name="comments" {...register("comments", { required: false, minLength: 5, maxLength: 285 })} disabled={disabledField}></textarea>
                                                        <small className="form-text text-danger">
                                                            {errors.comments?.type === "required" && "Comments is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.comments?.type === "minLength" && "Enter atleast 5 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.comments?.type === "maxLength" && "Only 285 characters allowed"}
                                                        </small>
                                                    </div>
                                                    {(orginfo.roleId === 1 || orginfo.roleId === 2) ? <div className="col-12 text-end">
                                                        <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                    </div> : null }
                                                </>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default FormModal;