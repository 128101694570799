// libs
import React, {Fragment, useEffect, useState} from 'react';
import * as bootstrap from 'bootstrap';
// components 
import ConfirmationBox from '../../components/confirmation-box';
import FormModal from './form-modal';
import UpdateStockModal from './update-stock-modal';
import VariantModal from './variant-modal';
import HistoryListModal from './history-list-modal';
import ImagePreview from '../../components/image-preview-box';
// config & functions 
import config from '../../config';

function Table({loadapidata, offset, list, orgInfo, token}) {
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
	const [ShowVariantModalBox, setShowVariantModalBox] = useState(false);
	const [ShowStockModalBox, setShowStockModalBox] = useState(false);
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [ShowHistoryModalBox, setShowHistoryModalBox] = useState(false);
    const [RowId, setRowId] = useState(0);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);
    const deleteUrl = `${config.api.generic}/inventory`;

	const handFormModal = (ppid) => {
        setRowId(ppid);
        setShowStockModalBox(false);
        setShowFormModalBox(true);
        setShowDeleteModalBox(false);
        setShowVariantModalBox(false);
        setShowHistoryModalBox(false);
    }
	
    const handVariantModal = (ppid) => {
        setRowId(ppid);
        setShowStockModalBox(false);
        setShowFormModalBox(false);
        setShowDeleteModalBox(false);
        setShowVariantModalBox(true);
        setShowHistoryModalBox(false);
    }
	
    const handStockModal = (ppid, pvid) => {
        setRowId({ppid, pvid});
        setShowStockModalBox(true);
        setShowFormModalBox(false);
        setShowDeleteModalBox(false);
        setShowVariantModalBox(false);
        setShowHistoryModalBox(false);
    }

    const handleDelete = (rowId) => {
        setRowId(rowId);
        setShowStockModalBox(false);
        setShowFormModalBox(false);
        setShowDeleteModalBox(true);
        setShowVariantModalBox(false);
        setShowHistoryModalBox(false);
    }
    
    const handleDeleteVariant = (ppid, pvid) => {
        setRowId({ppid, pvid});
        setShowStockModalBox(false);
        setShowFormModalBox(false);
        setShowDeleteModalBox(true);
        setShowVariantModalBox(false);
        setShowHistoryModalBox(false);
    }
    
    const handStockHistoryModal = (ppid, pvid) => {
        setRowId({ppid, pvid});
        setShowStockModalBox(false);
        setShowFormModalBox(false);
        setShowDeleteModalBox(false);
        setShowVariantModalBox(false);
        setShowHistoryModalBox(true);
    }

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orgInfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    const PrintImage = ({img}) => {
        if(img) {
            let sa = img.split(",");
            return <div className='bg-secondary rounded-circle d-flex justify-content-center align-items-center' style={{width: '40px', height: '40px'}} onClick={e => handleViewImage(true, sa[0])}>
                <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${sa[0]}`} className='img-fluid' style={{width: '40px', height: '40px', borderRadius: '50%'}} />
            </div>
        } else {
            return <div className='bg-secondary rounded-circle' style={{width: '40px', height: '40px'}}></div>
        }
    }

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table fs-14rem">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Account</th>
                            <th style={{minWidth: '200px'}}>Product Name</th>
                            <th style={{minWidth: '140px'}}>Total Variants</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((data,i) => {
                            return <Fragment key={Math.random()}>
                                <tr style={{background: '#e6e6e6'}}>
                                    <td style={{padding: '0.8rem 0.3rem'}} className="vt-txt-dark-orange fw-bold" valign="middle">{i+1}</td>
                                    <td style={{padding: '0.8rem 0rem'}} className="vt-txt-dark-orange fw-bold" valign="middle">{data.org_account_label} ({data.warehouse_label})</td>
                                    <td style={{padding: '0.8rem 0rem'}} valign="middle">
                                        <div className="d-flex align-items-center">
                                            <PrintImage img={data.product_image} />
                                            <span className="ms-2 vt-txt-dark-orange fw-bold">{data.product_name}</span>
                                        </div>
                                    </td>
                                    <td style={{padding: '0.8rem 0rem'}} className="vt-txt-dark-orange fw-bold" valign="middle">{data.variant?.length || 0}</td>
                                    <td style={{minWidth: '240px', padding: '0.8rem 0rem'}} valign="middle">
                                        <div className="d-flex p-0">
                                            <button onClick={() => handVariantModal(data.pk_id)} className="me-2 p-0 border-0 bg-transparent text-primary">Add Variant</button>
                                            <button onClick={() => handFormModal(data.pk_id)} className="border-0 bg-transparent text-primary">Edit</button>
                                            <button onClick={() => handleDelete(data.pk_id)} className="border-0 bg-transparent text-danger">Delete</button>
                                        </div>
                                    </td>
                                </tr>
                                {data.variant && <tr key={Math.random()}>
                                    <td colSpan={5} className="px-5 py-2">
                                        <table className="table table-sm fs-14rem mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{minWidth: '200px'}}>SKU Id</th>
                                                    <th style={{minWidth: '80px'}}>Color</th>
                                                    <th style={{minWidth: '145px'}}>Size</th>
                                                    <th style={{minWidth: '120px'}}>Inward Stock</th>
                                                    <th style={{minWidth: '100px'}}>Total Sales</th>
                                                    <th style={{minWidth: '120px'}}>Available Stock</th>
                                                    <th style={{minWidth: '120px'}}>Invested Amt</th>
                                                    <th style={{minWidth: '120px'}}>Earnings</th>
                                                    <th style={{minWidth: '120px'}}>Loss</th>
                                                    <th style={{minWidth: '240px'}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.variant.map(v => {
                                                    return <tr key={Math.random()}>
                                                        <td>{v.skuid}</td>
                                                        <td>{v.color_label}</td>
                                                        <td>{v.size_label}</td>
                                                        <td>{v.total_inward_stock - v.total_cutoff_stock}</td>
                                                        <td>{v.total_sales}</td>
                                                        <td>{(v.total_inward_stock - v.total_cutoff_stock) - v.total_sales}</td>
                                                        <td>Rs {v.total_invested_amount - v.total_cutoff_amount}/-</td>
                                                        <td>Rs {v.settlement_amt}/-</td>
                                                        <td>Rs {v.penalty_amt}/-</td>
                                                        <td>
                                                            <button onClick={() => handStockModal(data.pk_id, v.pk_id)} className="me-2 p-0 border-0 bg-transparent text-primary">Update Stock</button>
                                                            <button onClick={() => handleDeleteVariant(data.pk_id, v.pk_id)} className="me-2 border-0 border-0 bg-transparent text-danger">Delete</button>
                                                            <button onClick={() => handStockHistoryModal(data.pk_id, v.pk_id)} className="me-2 p-0 border-0 bg-transparent text-primary">History</button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>}
                            </Fragment>
                        })}
                    </tbody>
                </table>
            </div>
            {(ShowDeleteModalBox) ? <ConfirmationBox loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={(RowId?.ppid && RowId?.pvid) ? {inventoryId: RowId?.ppid, variantId: RowId?.pvid} : {id: RowId}} token={token} /> : null}
            {(ShowFormModalBox) ? <FormModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} rowid={RowId} token={token} orginfo={orgInfo} /> : null}
            {(ShowStockModalBox) ? <UpdateStockModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowstockmodalbox={setShowStockModalBox} rowid={RowId} token={token} orginfo={orgInfo} /> : null}
            {(ShowVariantModalBox) ? <VariantModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowvariantmodalbox={setShowVariantModalBox} rowid={RowId} token={token} orginfo={orgInfo} /> : null}
            {(ShowHistoryModalBox) ? <HistoryListModal setshowhistorymodalbox={setShowHistoryModalBox} rowid={RowId} token={token} orginfo={orgInfo} /> : null}
            {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
        </>
    } else {
        return null;
    }
}

export default Table;