// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/css/form.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components 
import Loading from '../../components/loading';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";
import utils from '../../utils';

function UpdateStockModal({loadapidata, apidataparams, setshowstockmodalbox, rowid, token, orginfo}) {

    const [InputFields, setInputFields] = useState({
        accountId: "",
        accountLabel: "",
        warehouseLabel: "",
        sellerAccountId: "",
        productName: "",
        inwardQty: "",
        inwardSuffix: "",
        skuId: "",
        colorId: "",
        sizeId: "",
        amount: "",
        tax: "",
        totalAmount: "",
        deliveryDate: "",
        invoiceNo: "",
        comments: "",
        stockOperation: ""
    });
    const wrapperRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState('SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (rowid?.ppid) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [AccountNames, setAccountNames] = useState(null);
    const [ColorList, setColorList] = useState([]);
    const [SizeList, setSizeList] = useState([]);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowstockmodalbox(false)
	}

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        let nDeliveryDate = utils.getDateFromTimestamp(data.deliveryDate);
        let postData = {
            accountId: InputFields.accountId,
            sellerAccountId: data.sellerAccountId,
            inventoryId: (rowid?.ppid) ? rowid?.ppid : 0,
            variantId: (rowid?.pvid) ? rowid?.pvid : 0,
            inwardQty: data.inwardQty,
            inwardSuffix: data.inwardSuffix,
            amount: data.amount,
            tax: data.tax,
            deliveryDate: nDeliveryDate,
            invoiceNo: data.invoiceNo,
            comments: data.comments,
            stockOperation: data.stockOperation
        }
        let apiCall = await ajaxCall.postCall(`${config.api.generic}/inventory/stock`, postData, token);
        if(apiCall && apiCall.status) {
            setSubmitButton('SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
            loadapidata(apidataparams); // refresh list page
        } else {
            setSubmitButton('SAVE RECORD');
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }
    
    const fetchColorList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/color`, {}, token);
        if(apiCall && apiCall.status) {
            setColorList(apiCall.context.message.data);
        }
    }

    const fetchSizeList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/size`, {}, token);
        if(apiCall && apiCall.status) {
            setSizeList(apiCall.context.message.data);
        }
    }

    const fetchAccountNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/get-names`, {}, token);
        if(apiCall && apiCall.status) {
            setAccountNames(apiCall.context.message.data);
        } else {
            setAccountNames("Something went wrong. Please try agian")
        }
    }
    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (rowid?.ppid && rowid?.pvid) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/inventory/stock-update/row`, {
                    inventoryId: (rowid?.ppid) ? rowid?.ppid : 0,
                    variantId: (rowid?.pvid) ? rowid?.pvid : 0,
                }, token);
                if(apiCall && apiCall.status) {
                    let onLoadData = {
                        accountId: apiCall.context.message.data[0].fk_org_account_id,
                        accountLabel: apiCall.context.message.data[0].org_account_name,
                        warehouseLabel: apiCall.context.message.data[0].warehouse_label,
                        sellerAccountId: '',
                        productName: apiCall.context.message.data[0].product_name,
                        inwardQty: '',
                        inwardSuffix: '',
                        skuId: apiCall.context.message.data[0].skuid,
                        colorId: apiCall.context.message.data[0].fk_color_id,
                        sizeId: apiCall.context.message.data[0].fk_size_id,
                        amount: '',
                        tax: '',
                        totalAmount: '',
                        deliveryDate: '',
                        invoiceNo: '',
                        comments: '',
                        stockOperation: ''
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }
        fetchAccountNames();
        fetchData();
        fetchColorList();
        fetchSizeList();
        loadComponent();
    }, []);

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{rowid?.ppid?'Update Stock Record':'Add Stock Record'}</h5>
                            <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            {(Notification.status) ?
                                                <div className="col-12 text-center">{Notification.message}</div> : <>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Stock Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Choose Client <span className="fs-11rem">(Seller Account)</span></label>
                                                        <input type="text" defaultValue={InputFields.accountLabel} className="form-control" disabled={true} />
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Choose Warehouse</label>
                                                        <input type="text" defaultValue={InputFields.warehouseLabel} className="form-control" disabled={true} />
                                                    </div>
                                                    <div className={`col-md-4 mt-3`}>
                                                        <label className="form-label fs-14rem fw-bold">Choose Seller <span className="fs-11rem">(Supplier)</span></label>
                                                        {(AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0) ? <><Controller
                                                            name='sellerAccountId' 
                                                            defaultValue={InputFields.sellerAccountId}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="sellerAccountId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={false}>
                                                                    <option value=""> Choose...</option>
                                                                    {AccountNames.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    })}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.sellerAccountId?.type === "required" && "Please choose Seller"}
                                                        </small></> : (AccountNames === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{AccountNames}</div> }
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Product Name</label>
                                                        <input type="text" defaultValue={InputFields.productName} className="form-control" name="productName" {...register("productName", { required: false, minLength: 1, maxLength: 122 })} disabled={true} />
                                                        <small className="form-text text-danger">
                                                            {errors.productName?.type === "required" && "Product Name is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.productName?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.productName?.type === "maxLength" && "Only 122 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Color</label>
                                                        <Controller
                                                            name='colorId' 
                                                            defaultValue={InputFields.colorId}
                                                            rules={{required: false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="colorId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={true}>
                                                                    <option value=""> Choose...</option>
                                                                    {(Array.isArray(ColorList) && ColorList.length > 0) ? ColorList.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    }):null}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.colorId?.type === "required" && "Please update color"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Size</label>
                                                        <Controller
                                                            name='sizeId' 
                                                            defaultValue={InputFields.sizeId}
                                                            rules={{required: false}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="sizeId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={true}>
                                                                    <option value=""> Choose...</option>
                                                                    {(Array.isArray(SizeList) && SizeList.length > 0) ? SizeList.map(d => {
                                                                        return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                    }):null}
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.sizeId?.type === "required" && "Please update size"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">SKU Id</label>
                                                        <input type="text" defaultValue={InputFields.skuId} className="form-control" name="skuId" {...register("skuId", { required: false, minLength: 1, maxLength: 125 })} disabled={true} />
                                                        <small className="form-text text-danger">
                                                            {errors.skuId?.type === "required" && "Sku Id is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.skuId?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.skuId?.type === "maxLength" && "Only 125 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Operation</label>
                                                        <Controller
                                                            name='stockOperation' 
                                                            defaultValue={InputFields.stockOperation}
                                                            rules={{required: true}}
                                                            control={control} 
                                                            render={({field}) => (
                                                                <select value={field.value || ""} className="form-control custom-select" name="stockOperation" onChange={(e) => {updateChangeEvent(e, field)}}>
                                                                    <option value=""> Choose...</option>
                                                                    <option value="Inward"> Add Stock</option>
                                                                    <option value="CutOff"> CutOff Stock</option>
                                                                </select>
                                                            )}
                                                        />	
                                                        <small className="form-text text-danger">
                                                            {errors.stockOperation?.type === "required" && "Please update stock operation"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mt-3">
                                                        <div><label htmlFor="deliveryDate" className="form-label fs-14rem fw-bold">Delivery Date</label></div>
                                                        <div className="react-datepicker-wrapper-fullwidth">
                                                            <Controller
                                                                control={control}
                                                                name="deliveryDate"
                                                                defaultValue={InputFields.deliveryDate} 
                                                                rules={{ required: true }}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            className="form-control w-100"
                                                                            selected={field.value}
                                                                            disabled={false} 
                                                                            format="DD" 
                                                                            onChange={(date) => {
                                                                                field.onChange(date);
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <small className="form-text text-danger">
                                                            {errors.deliveryDate?.type === "required" && "Delivery date is mandatory"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Inward Quantity</label>
                                                        <input type="text" defaultValue={InputFields.inwardQty} className="form-control" name="inwardQty" {...register("inwardQty", { required: true, minLength: 1, maxLength: 7 })} disabled={false} />
                                                        <small className="form-text text-danger">
                                                            {errors.inwardQty?.type === "required" && "Inward Quantity is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.inwardQty?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.inwardQty?.type === "maxLength" && "Only 7 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Inward Suffix</label>
                                                        <input type="text" defaultValue={InputFields.inwardSuffix} className="form-control" placeholder="pcs,kit,box etc" name="inwardSuffix" {...register("inwardSuffix", { required: true, minLength: 1, maxLength: 12 })} disabled={false} />
                                                        <small className="form-text text-danger">
                                                            {errors.inwardSuffix?.type === "required" && "Inward Suffix Suffix is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.inwardSuffix?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.inwardSuffix?.type === "maxLength" && "Only 12 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Amount (Per Pcs)</label>
                                                        <input type="text" defaultValue={InputFields.amount} className="form-control" name="amount" {...register("amount", { required: true, minLength: 1, maxLength: 22 })} disabled={false} />
                                                        <small className="form-text text-danger">
                                                            {errors.amount?.type === "required" && "Amount is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.amount?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.amount?.type === "maxLength" && "Only 22 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Tax</label>
                                                        <input type="text" defaultValue={InputFields.tax} className="form-control" name="tax" {...register("tax", { required: true, minLength: 1, maxLength: 22 })} disabled={false} />
                                                        <small className="form-text text-danger">
                                                            {errors.tax?.type === "required" && "Tax is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.tax?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.tax?.type === "maxLength" && "Only 22 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Invoice No</label>
                                                        <input type="text" defaultValue={InputFields.invoiceNo} className="form-control" name="invoiceNo" {...register("invoiceNo", { required: false, minLength: 1, maxLength: 22 })} disabled={false} />
                                                        <small className="form-text text-danger">
                                                            {errors.invoiceNo?.type === "required" && "Invoice No is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.invoiceNo?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.invoiceNo?.type === "maxLength" && "Only 22 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Comments?</label>
                                                        <input type="text" defaultValue={InputFields.comments} className="form-control" name="comments" {...register("comments", { required: false, minLength: 1, maxLength: 250 })} disabled={false} />
                                                        <small className="form-text text-danger">
                                                            {errors.comments?.type === "required" && "Comments is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.comments?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.comments?.type === "maxLength" && "Only 250 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-4 mt-3"></div>
                                                    {(orginfo.roleId === 1 || orginfo.roleId === 2) ? <div className="col-12 text-end">
                                                        <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                    </div> : null }
                                                </>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default UpdateStockModal;