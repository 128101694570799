// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import "../../assets/css/form.css";
// components 
import Loading from '../../components/loading';
import GalleryBox from '../../components/gallery-box';
import ImagePreview from '../../components/image-preview-box';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";
import utils from '../../utils';

function FormModal({loadapidata, apidataparams, setshowformmodalbox, rowid, token, orginfo}) {

    const [InputFields, setInputFields] = useState({
        rowId: "",
        productName: "",
        productDimension: "",
        productImg: "",
    });
    const disabledField = (orginfo.roleId === 1 || orginfo.roleId === 2) ? false:true;
    const wrapperRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState((rowid) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (rowid) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [BrowseImage, setBrowseImage] = useState(false);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orginfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowformmodalbox(false)
	}

    const handleInputState = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const removeProductImage = (i) => {
        const imgArrList = (InputFields.productImg) ? InputFields.productImg.split(",") : null;
        if(imgArrList.length > 0) {
            imgArrList.splice(i,1);
        }
        handleInputState('productImg', imgArrList.join()) 
    }

    const handleProductImageName=((val)=>{ 
        handleInputState('productImg', val) 
    })

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        let postData = {
            rowId: rowid,
            productName: data.productName,
            productDimension: data.productDimension,
            productImg: InputFields.productImg
        }
        let apiCall = "";
        if(InputFields.rowId) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/product-dimensions`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/product-dimensions`, postData, token);
        }
        
        if(apiCall && apiCall.status) {
            setSubmitButton((InputFields.rowId)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
            loadapidata(apidataparams); // refresh list page
        } else {
            setSubmitButton((InputFields.rowId)?'UPDATE RECORD':'SAVE RECORD');
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }
    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (rowid) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/product-dimensions/row`, {
                    rowId: (rowid) ? rowid : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let onLoadData = {
                        rowId: rowid,
                        productName: apiCall.context.message.data[0].product_name,
                        productDimension: apiCall.context.message.data[0].product_dimension,
                        productImg: apiCall.context.message.data[0].product_image,
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }
        fetchData();
        loadComponent();
    }, []);

    const imgArrList = (InputFields.productImg) ? InputFields.productImg.split(",") : null;

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{rowid?'Update Dimensions':'Add Dimensions'}</h5>
                            <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            {(Notification.status) ?
                                                <div className="col-12 text-center">{Notification.message}</div> : <>
                                                    <div className="col-md-12">
                                                        <div className="text-left mt-3 vt-txt-dark-blue">
                                                            <h4><b>Dimension Info</b></h4>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Product Name</label>
                                                        <input type="text" defaultValue={InputFields.productName} className="form-control" name="productName" {...register("productName", { required: true, minLength: 1, maxLength: 125 })} disabled={disabledField} />
                                                        <small className="form-text text-danger">
                                                            {errors.productName?.type === "required" && "Product Name is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.productName?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.productName?.type === "maxLength" && "Only 125 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className="form-label fs-14rem fw-bold">Product Dimension</label>
                                                        <textarea defaultValue={InputFields.productDimension} className="form-control" name="productDimension" {...register("productDimension", { required: true, minLength: 1, maxLength: 125 })} disabled={disabledField}></textarea>
                                                        <small className="form-text text-danger">
                                                            {errors.productDimension?.type === "required" && "Product Dimension is mandatory"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.productDimension?.type === "minLength" && "Enter atleast 1 characters"}
                                                        </small>
                                                        <small className="form-text text-danger">
                                                            {errors.productDimension?.type === "maxLength" && "Only 125 characters allowed"}
                                                        </small>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        {(orginfo.roleId === 1 || orginfo.roleId === 3) ? <button type="button" className="btn btn-sm btn-primary" onClick={() => setBrowseImage(true)}>Browse Gallery</button> : null}
                                                        <div className="mt-2">
                                                            {(imgArrList && Array.isArray(imgArrList) && imgArrList.length > 0)? imgArrList.map((d,i) => {
                                                                return <span className="me-2 border border-2 rounded py-1 px-2" key={Math.random()}>{d} <FontAwesomeIcon icon={faSearch} onClick={e => handleViewImage(true, d)} className="dashboard-card-icon text-primay cursor-pointer" /> {(orginfo.roleId === 1 || orginfo.roleId === 3) ? <FontAwesomeIcon icon={faTrash} onClick={e => removeProductImage(i)} className="dashboard-card-icon text-danger" /> : null}</span>
                                                            }):null}
                                                        </div>
                                                    </div>
                                                    {(orginfo.roleId === 1 || orginfo.roleId === 2) ? <div className="col-12 text-end">
                                                        <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                    </div> : null }
                                                </>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {(BrowseImage)? <GalleryBox orgInfo={orginfo} handlegalleryimagenamefn={handleProductImageName} setbrowseimage={setBrowseImage} selectedimg={InputFields.productImg} token={token} action="selectable" />:null}
            {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
        </>
    )
}

export default FormModal;