// libs
import React, {useEffect, useState} from 'react'
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import Navbar from "../../components/navbar";
import FormModal from './form-modal';
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Table from './table';
import Record404 from '../../components/record404';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function List({token, orgInfo}) {
	let query = utils.useQuery();
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [SearchQInputVal, setSearchQInputVal] = useState(null);
	// get query param
	let paginationNo = query.get("page");
	let urlSearchQ = query.get("searchQ");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;
	let offset = (activePage*config.pagination.limitPerPage)-config.pagination.limitPerPage;

	const loadApiData = async (offset) => {
		setIsLoading(true);
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/product-dimensions`, {
			offset:offset, 
			limit: config.pagination.limitPerPage, 
			fSearchQ: urlSearchQ, 
		}, token);
        if(apiCall && apiCall.status) {
            setListData(apiCall.context.message.data.records);
            setTotalListCount(apiCall.context.message.data.totalCount);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }

	const handlePageChange = async (pageNumber) => {
		const url = new URL(window.location.href);
		if (url.searchParams.has('page')) {
			url.searchParams.delete('page');
		} 
		url.searchParams.append('page', pageNumber);
		window.location.href = url.toString();
	}

	const handleChangeSearchQuery = (event) => {
		setSearchQInputVal(event.target.value);
	}

	const submitSearchQuery = () => {
		const url = new URL(window.location.href);
		if (url.searchParams.has('searchQ')) {
			url.searchParams.delete('searchQ');
		} 
		url.searchParams.delete('page');
		url.searchParams.append('searchQ', SearchQInputVal);
		window.location.href = url.toString();
	} 

	useEffect(() => {
        async function fetchData() {
			loadApiData(offset); // offset should start from 0
        };
        fetchData();
    }, []);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Package Dimensions</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: `Package Dimensions - (${TotalListCount})`, url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type="button" onClick={() => setShowFormModalBox(true)} className="vt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">ADD DIMENSIONS</button>
                        </div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col-12 col-md-6 col-lg-5 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Product Name</label></div>
						<div className="input-group">
							<input type="text" className="form-control" defaultValue={urlSearchQ} onKeyUp={handleChangeSearchQuery} placeholder="Product Name" aria-label="Product Name" aria-describedby="basic-addon2" />
							<div className="input-group-append">
								<button className="btn btn-outline-secondary" onClick={submitSearchQuery} type="button">Search</button>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col">
						{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <Table loadapidata={loadApiData} offset={offset} list={ListData} orgInfo={orgInfo} token={token} />}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
			{(ShowFormModalBox) ? <FormModal loadapidata={loadApiData} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} productid={0} token={token} orginfo={orgInfo} /> : null}
		</>
	)
}

export default List;