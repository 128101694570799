// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as bootstrap from 'bootstrap';
// components 
import ConfirmationBox from '../../components/confirmation-box';
import FormModal from './form-modal';
import ImagePreview from '../../components/image-preview-box';
// config & functions 
import config from '../../config';

function Table({loadapidata, offset, list, orgInfo, token}) {
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [RowId, setRowId] = useState(0);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);
    const deleteUrl = `${config.api.generic}/product-dimensions`;

	const handFormModal = (status, ppid) => {
        setRowId(ppid);
        setShowFormModalBox(status);
    }

    const handleDelete = (rowId) => {
        setRowId(rowId);
        setShowDeleteModalBox(true);
    }

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orgInfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    const PrintImage = ({img}) => {
        if(img) {
            let sa = img.split(",");
            return <div className='bg-secondary rounded-circle d-flex justify-content-center align-items-center' style={{width: '40px', height: '40px'}} onClick={e => handleViewImage(true, sa[0])}>
                <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${sa[0]}`} className='img-fluid' style={{width: '40px', height: '40px', borderRadius: '50%'}} />
            </div>
        } else {
            return <div className='bg-secondary rounded-circle' style={{width: '40px', height: '40px'}}></div>
        }
    }

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-hover fs-14rem">
                    <thead>
                        <tr>
                            <th style={{minWidth: '80px'}}>ID</th>
                            <th style={{minWidth: '280px'}}>Product Name</th>
                            <th style={{minWidth: '340px'}}>Product Dimensions</th>
                            <th style={{minWidth: '120px'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((data, i) => {
                            return <tr key={Math.random()} style={{color: (data.is_returned && data.is_returned === 'Yes')? `#ff0000`:`#000`}}>
                                <td style={{padding: '0.8rem 0.3rem'}} valign="middle">{i+1}</td>
                                <td style={{padding: '0.8rem 0rem'}} valign="middle">
                                    <div className="d-flex align-items-center">
                                        <PrintImage img={data.product_image} />
                                        <span className="ms-2 vt-txt-dark-orange fw-bold">{data.product_name}</span>
                                    </div>
                                </td>
                                <td valign="middle">{data.product_dimension}</td>
                                <td valign="middle">
                                    <div style={{width: '100px'}} className="d-flex">
                                        <button onClick={() => handFormModal(true, data.pk_id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faPen} className="dashboard-card-icon" /></button>
                                        <button onClick={() => handleDelete(data.pk_id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(ShowDeleteModalBox) ? <ConfirmationBox loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={{id:RowId}} token={token} /> : null}
            {(ShowFormModalBox) ? <FormModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} rowid={RowId} token={token} orginfo={orgInfo} /> : null}
            {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
        </>
    } else {
        return null;
    }
}

export default Table;