// libs
import React, {useEffect, useRef, useState} from 'react'
import * as bootstrap from 'bootstrap';
import { toast } from 'react-toastify';
// components 
import Loading from "./loading";
import Record404 from './record404';
// config & functions 
import ajaxCall from '../utils/ajaxcall'
import config from '../config';
import utils from '../utils';

function GalleryBox({orgInfo, handlegalleryimagenamefn, setbrowseimage, selectedimg, token, action='view'}) {

    const wrapperRef = useRef(null);
    const [SelectedProduct, setSelectedProduct] = useState(selectedimg);
    const [IsLoading, setIsLoading] = useState(false);
    const [LoadMoreImg, setLoadMoreImg] = useState(false);
    const [ImgList, setImgList] = useState({nextToken: '', data: ''});
    const [Is404, setIs404] = useState(false);
    const [ImgLocation, setImgLocation] = useState('product');

    const showModal = () => {
        const modalEle = wrapperRef.current
        const bsModal = new bootstrap.Modal(modalEle, {
            backdrop: false,
            keyboard: false
        })
        bsModal.show()
    }

    const hideModal = () => {
		const modalEle = wrapperRef.current
		const bsModal = bootstrap.Modal.getInstance(modalEle)
		bsModal.hide();
        setbrowseimage(false); // remove component from parent div
	}

    const getImages = async () => {
        setIsLoading(true);
        let apiCall = await ajaxCall.getCall(`${config.api.image}/files`, {projectName: 'inhouseEcommSAS', clientDomain: orgInfo.domain, imgLocation: ImgLocation}, token);
        if(apiCall && apiCall.status) {
            setImgList(apiCall.context.message.data);
            setIsLoading(false);
        } else {
            toast.error('Something went wrong. Please try again :(');
            setIsLoading(false);
            setIs404(true);
        }
    }

    const ListImages = ({list}) => {
        if(Array.isArray(list) && list.length > 0) {
            return <div className="container" key={Math.random()}>
                <div className="row row-cols-6">
                    {list.map((d,i) => {
                        let fname = d.name.substring(d.name.lastIndexOf('/')+1)
                        let selected = false;
                        if(SelectedProduct && SelectedProduct.split(',').indexOf(fname) !== -1) {
                            selected = true;
                        }
                        let imgPath = "";
                        if(ImgLocation === "generic") {
                            imgPath = "generic";
                        } else if (ImgLocation === "banner") {
                            imgPath = "banner";
                        } else if (ImgLocation === "product") {
                            imgPath = "products/large";
                        } else {
                            imgPath = "card";
                        }
                        return <div className="col" key={Math.random()}>
                            {(action === "selectable") ? <><button type='button' className="bg-transparent border-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title={fname}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                                </svg></button>
                                <div className={(selected)?'border border-dark border-2':''} onClick={(e) => chooseSelection(d.name)}>
                                    <img src={`${d.url}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title={fname} className="py-2" width="100%" />
                                </div></> : <>
                                <button type='button' className="bg-transparent border-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title={fname}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                                </svg></button>
                                <a target="_blank" href={`${config.assets.products}/${orgInfo.domain}/${imgPath}/${fname}`}>
                                    <img className="py-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title={fname} src={`${d.url}`} width="100%" />
                                </a>
                            </>}
                        </div>
                    })}
                </div>
            </div>
        } else {
            return null;
        }
    }

    const saveSelection = () => {
        const pString = SelectedProduct;
        handlegalleryimagenamefn(pString);
        hideModal();
    }

    const chooseSelection = async (img) => {
        let fname = await utils.fileNameFromPath(img);
        // if available, remove it else add 
        if(SelectedProduct && SelectedProduct.split(',').indexOf(fname) !== -1) {
            let arr = SelectedProduct.split(',');
            let index = arr.indexOf(fname);
            arr.splice(index,1);
            setSelectedProduct(arr.join())
        } else {
            let fname = await utils.fileNameFromPath(img);
            let sp = SelectedProduct;
            let newProduct = fname;
            if(sp) {
                newProduct = `${sp},${fname}`
            }
            setSelectedProduct(newProduct)
        }
    }

    const loadMore = async (nextToken) => {
        setLoadMoreImg(true);
        let apiCall = await ajaxCall.getCall(`${config.api.image}/files`, {projectName: 'inhouseEcommSAS', imgLocation: ImgLocation, clientDomain: orgInfo.domain, nextToken: nextToken}, token);
        if(apiCall && apiCall.status) {
            if(apiCall.context.message.data.data.length > 0) {
                let records = ImgList;
                records.nextToken = (apiCall.context.message.data.hasOwnProperty('nextToken') && apiCall.context.message.data.nextToken !== '')?apiCall.context.message.data.nextToken:"";
                records.data = [...records.data, ...apiCall.context.message.data.data];
                setImgList(records);
                setLoadMoreImg(false);
            } else {
                let records = ImgList;
                records.nextToken = "";
                setImgList(records);
                setLoadMoreImg(false);
            }
        }
    }

    const LoadMoreBtn = ({token}) => {
        return <div className="text-center py-3"><button type="button" disabled={(LoadMoreImg)?true:false} className="btn btn-primary btn-sm fs-14rem" onClick={() => loadMore(token)}>{(LoadMoreImg)?'Loading...':'Load More'}</button></div>
    }   

    useEffect(() => {
        async function loadComponent() {
            setSelectedProduct(selectedimg);
            showModal();
            getImages();
        }
        loadComponent();
    },[ImgLocation]);

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [ImgList])

    console.log('ImgList', ImgList)

    return (
        <div className="modal modal-lg fade" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
			<div className="modal-dialog">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Browse Gallery</h5>
					<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
				</div>
				<div className="modal-body" style={{maxHeight: '400px', overflowY: 'scroll'}}>
                    <div>
                        <select value={ImgLocation} className="form-control custom-select border mb-2" name="imgLocation" onChange={(e) => {setImgList({nextToken: '', data: ''}); setImgLocation(e.target.value)}}>
                            <option value=""> Choose...</option>
                            <option value="generic">Generic (Freestyle)</option>
                            <option value="banner">Banner (1800x750)</option>
                            <option value="product">Product (1200x1200)</option>
                            <option value="card">Card (416x416)</option>
                        </select>
                    </div>
					{(IsLoading) ? <Loading /> : null}
                    {(ImgList) ? <ListImages list={ImgList.hasOwnProperty('data')?ImgList.data:[]} /> : null}
                    {(Is404) ? <Record404 /> : null}
                    {(ImgList && ImgList.hasOwnProperty('nextToken') && ImgList.nextToken !== '') ? <LoadMoreBtn token={ImgList.nextToken} /> : null}
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                    {(action === 'view') ? null:<button type="button" className="btn btn-primary fs-14rem" disabled={(SelectedProduct)?false:true} onClick={() => saveSelection()}>Save</button>}
				</div>
				</div>
			</div>
		</div>
    )
}
export default GalleryBox;
